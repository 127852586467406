:root {
  --components-input-l-border-radius: 6px;
  --components-input-primary-bg-color-default: rgba(255, 255, 255, 1);
  --components-input-primary-border-color-default: rgba(68, 83, 113, 0.15);
  --components-textarea-l-height: 100px;
  --components-input-l-labelinside-spacing:0px;
  --components-scroll-track-bg-color-default:rgba(68, 83, 113, 0.1);
  --components-scroll-knob-bg-color-default:rgba(161, 169, 184, 1);
  --components-scroll-size:4px;
  --components-input-l-labeloutside-spacing:8px;
}

.text-area {
  gap: var(--components-input-l-labeloutside-spacing);
  height: 395px;
  left: 16px;
  top: 173px;
  width: 100%;

  .overlap-group {
    border-radius: 6px;
    height: 395px;
    position: relative;

    .input-container {
      background-color: var(--components-input-primary-bg-color-default);
      border: 1px solid;
      border-color: var(--components-input-primary-border-color-default);
      border-radius: var(--components-input-l-border-radius);
      box-shadow: inset 0px 0px 0px transparent;
      height: 395px;
      left: 0;
      min-height: var(--components-textarea-l-height);
      position: absolute;
      top: 0;
      width: 100%;

      .scroll-instance {
        height: 328px !important;
        position: relative !important;
        left: 99% !important;
        top: 61px !important;
      }

      .design-component-instance-node {
        height: 137px !important;
        top: 48px !important;
      }
    }

    .text-container {
      gap: var(--components-input-l-labelinside-spacing);
      height: 383px;
      left: 12px;
      position: absolute;
      width: 100%;

      .text-wrapper {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        font-size: 20px;
        font-weight: 500;
        left: 12px;
        letter-spacing: 0;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        top: 10px;
        white-space: nowrap;
      }

      .div {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        top: 10px;
        white-space: nowrap;
      }

      .text-wrapper-2 {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        top: 10px;
        white-space: nowrap;
      }

      .line {
        height: 1px;
        left: -12px;
        object-fit: cover;
        position: absolute;
        top: 46px;
        width: 100%;
      }

      .frame-2 {
        height: 83%;
        position: absolute;
        top: 60px;
        width: 96%;
        overflow: auto;
        scrollbar-width: none;

        .frame-3 {
          height: 84px;
          position: absolute;
          top: 0;
          width: 100%;

          .text-wrapper-3 {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            font-size: 16px;
            font-weight: 400;
            left: 12px;
            letter-spacing: 0;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            top: -1px;
            white-space: nowrap;
          }

          .text-wrapper-4 {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .frame-4 {
            height: 84px;
            top: 0;
            width: 149px;

            .text-wrapper-5 {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0;
              line-height: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .text-wrapper-6 {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              font-size: 16px;
              font-weight: 400;
              left: 0;
              letter-spacing: 0;
              line-height: 24px;
              overflow: hidden;
              position: absolute;
              text-overflow: ellipsis;
              top: 35px;
              white-space: nowrap;
            }

            .text-wrapper-7 {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #0055ff;
              display: -webkit-box;
              font-size: 16px;
              font-weight: 400;
              left: 56px;
              letter-spacing: 0;
              line-height: 24px;
              overflow: hidden;
              position: absolute;
              text-overflow: ellipsis;
              top: 35px;
              white-space: nowrap;
            }

            .text-wrapper-8 {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              font-size: 16px;
              font-weight: 400;
              left: 0;
              letter-spacing: 0;
              line-height: 24px;
              overflow: hidden;
              position: absolute;
              text-overflow: ellipsis;
              top: 59px;
              white-space: nowrap;
            }

            .text-wrapper-9 {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              font-size: 16px;
              font-weight: 400;
              left: 64px;
              letter-spacing: 0;
              line-height: 24px;
              overflow: hidden;
              position: absolute;
              text-overflow: ellipsis;
              top: 59px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.scroll {
  background-color: var(--components-scroll-track-bg-color-default);
  border-radius: 1px;
  height: 192px;
  width: var(--components-scroll-size);

  .knob {
    background-color: var(--components-scroll-knob-bg-color-default);
    border-radius: 4px;
    box-shadow: inset 0px 0px 0px transparent;
    height: 80px;
    position: relative;
    top: 28px;
  }
}
