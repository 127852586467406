@import '../../assets/scss/variables';

.layout {
  display: flex;
  min-height: 100vh;
  &_disabled {
    user-select: none;
    pointer-events: none;
  }
  &__menu {
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    min-height: 61px;
    padding: 5px 14px;
    border-bottom: 1px solid #D8D8D8;
    &:last-child {
      border: none;
      padding: 5px;
    }

    &_icon {
      padding: 5px;
      button {
        justify-content: center;
        width: 100% !important;
        padding-right: 7px !important;
      }
    }
  }
  &__text, &__link {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  &__link {
    justify-content: flex-start;
    gap: 15px;
    min-height: 40px;
    width: 100%;
    padding-left: 8px;
    &:hover {
      // background: $color-bg-hover-icon-menu !important;
      color: $color-hover-icon-menu !important;
      svg {
        color: $color-hover-icon-menu;
      }
    }
  }
  &__icon {
    width: 24px;
    height: 34px;
    color: #445371;
  }

}
.message {
  pointer-events: none !important;
  user-select: none !important;
  cursor: default;
}


.box-notification {
  height: 61px;
  padding: 5px 11px;
  border-bottom: 1px solid #D8D8D8;
}